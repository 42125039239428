import React from 'react';
import './App.css';
import FrontPage from "./components/FrontPage";

function App() {
  return (
    <FrontPage />
  );
}

export default App;
